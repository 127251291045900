import "./App.css";
import { BackGroundAnimationColor } from "./components/index";
import React, { useEffect, useState } from "react";
import { db } from "./config/firebase";
import { doc, setDoc, getDocs, collection } from "firebase/firestore";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
const Preloader = () => <div className="loader"></div>;

function App() {
  const [isTablet, setIsTablet] = useState(false);
  const [isActiveCampaign, setActiveCampaign] = useState(false);
  const [isTicketSystem, setTicketSystem] = useState(false);
  const [open, setOpen] = useState(false);
  const [currentTask, setCurrentTask] = useState(null);
  const [progress, setProgress] = useState(currentTask?.progress || "0");

  const handleProgressChange = (event) => {
    setProgress(event.target.value);
  };
  const onOpenModal = (task) => {
    setCurrentTask(task);
    setOpen(true);
    setProgress(task.progress);
  };
  const onCloseModal = () => setOpen(false);

  const showActiveCampaign = () => {
    hideTicketSystem();
    setActiveCampaign(true);
    document.getElementById("activeCampaignForm").style.display = "flex";
    document.getElementById("activeCampaignForm").reset();
    document.getElementById("newTicketForm").reset();
  };
  const hideActiveCampaign = () => {
    setActiveCampaign(false);
  };

  const showTicketSystem = () => {
    hideActiveCampaign();
    setTicketSystem(true);
    document.getElementById("tableContainer").style.display = "none";
    document.getElementById("activeCampaignForm").reset();
    document.getElementById("newTicketForm").reset();
  };

  const hideTicketSystem = () => {
    setTicketSystem(false);
  };
  useEffect(() => {
    const handleResize = () => {
      setIsTablet(window.innerWidth <= 884);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const goToDcSite = () => {
    window.location.href = "https://doorclosers.me/";
  };

  const [formData, setFormData] = useState({});
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value.trim());
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleString("en-US");

    const dateWithoutAmPm = formattedDate.slice(0, -3);
    const amPm = formattedDate.slice(-3);
    const milliseconds = currentDate
      .getMilliseconds()
      .toString()
      .padStart(3, "0");

    const formattedDateWithMilliseconds = `${dateWithoutAmPm}.${milliseconds}${amPm}`;

    const uid = formattedDateWithMilliseconds
      .replaceAll("/", "_")
      .replaceAll(":", "-")
      .replace(", ", "@")
      .replace(" ", "#");

    formData["progress"] = "0";
    const submitData = {
      [uid]: formData,
    };

    await setDoc(doc(db, "ticketSystem", formData.email), submitData, {
      merge: true,
    });
    // addDoc(collection(db, "mail"), {
    //   to: formData.email,
    //   message: {
    //     subject: "THX", // TODO: ADD CONTENT OF MAIL
    //     html: "thank you for your submission",
    //   },
    // });
    // addDoc(collection(db, "mail"), {
    //   to: "info@doorclosers.org",
    //   message: {
    //     subject: "New Inquiry",
    //     html: `<code><h1>New Inquiry</h1><h2>Name: ${formData.name}</h2><h2>Email: ${formData.email}</h2><h2>Where did you hear about us?: ${formData.whereDidYouHear}</h2><h2>Tell us about your project: ${formData.project}</h2><h2>Attach File Url: ${formData.fileUrl}</h2><h2>Message: ${formData.message}</h2></code>`,
    //   },
    // });
    document.getElementById("newTicketForm").reset();
    setIsSubmitting(false);
  };

  const [tasks, setTasks] = useState([]);
  const [isClient, setClient] = useState(false);

  const fetchData = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    const querySnapshot = await getDocs(collection(db, "ticketSystem"));
    const taskArray = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      Object.keys(data).forEach((taskId) => {
        taskArray.push({ id: taskId, ...data[taskId] });
      });
    });
    setTasks(taskArray);
    for (const index in taskArray) {
      if (taskArray[index].email === email) {
        setClient(true);
        break;
      }
    }
    setIsSubmitting(false);
    document.getElementById("activeCampaignForm").style.display = "none";
    document.getElementById("tableContainer").style.display = "block";
  };
  const handleModalSubmit = async (event) => {
    event.preventDefault();
    const { devDeadline, progressSlider } = event.target.elements;

    // Get the current date and format it to include only the date and hour
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;

    const updatedTask = {
      ...currentTask,
      devETA: devDeadline.value,
      progress: progressSlider.value,
    };

    // If progress is 100, disable dev-mode and update the submitted date
    if (progressSlider.value === "100") {
      updatedTask.devMode = false;
      updatedTask.submittedTime = formattedDate;
    } else {
      updatedTask.submittedTime = "";
    }

    const taskDocRef = doc(db, "ticketSystem", currentTask.email);
    const taskData = {
      [currentTask.id]: updatedTask,
    };

    await setDoc(taskDocRef, taskData, { merge: true });
    setOpen(false);

    // Update the tasks state to reflect the changes
    setTasks((prevTasks) =>
      prevTasks.map((task) => (task.id === currentTask.id ? updatedTask : task))
    );
  };
  return (
    <>
      {!isTablet && <BackGroundAnimationColor />}
      <div className="App">
        <header className="responsive-text-60 text-center mt-5 mb-5">
          Welcome To Our Ticket System
        </header>

        <div className="form-group custom-radio text-center">
          <label className="responsive-text-48 text-center">
            Which Action Would You Like To Perform:
          </label>
          <br />
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="companyType"
              id="activeCampaign"
              value="activeCampaign"
              onChange={showActiveCampaign}
            />
            <label
              className="form-check-label responsive-text-36"
              htmlFor="activeCampaign"
            >
              Active Campaign
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="companyType"
              id="newTicket"
              value="newTicket"
              onChange={showTicketSystem}
            />
            <label
              className="form-check-label responsive-text-36"
              htmlFor="newTicket"
            >
              New Ticket
            </label>
          </div>
        </div>
        <div className="text-center mx-3">
          <button type="button" className="hero-btn" onClick={goToDcSite}>
            I would like to know more about the service
          </button>
        </div>
        <div
          style={{ display: isTicketSystem ? "block" : "none" }}
          className={`${isTicketSystem ? "d-flex justify-content-center" : ""}`}
        >
          <form
            className="form-container mx-3"
            id="newTicketForm"
            onSubmit={handleSubmit}
          >
            <header className="responsive-text-60 mb-3">New Ticket</header>
            <div className="custom-input-container">
              <input
                type="text"
                name="name"
                className="custom-input"
                placeholder="Name:"
                required
                onChange={handleChange}
              />
            </div>
            <div className="custom-input-container">
              <input
                type="email"
                name="email"
                className="custom-input"
                placeholder="Email:"
                required
                onChange={handleChange}
              />
            </div>
            <div className="custom-input-container">
              <input
                type="tel"
                name="phone"
                id="phone"
                className="custom-input"
                placeholder="Phone:"
                onChange={handleChange}
                required
              />
            </div>
            <div className="custom-input-container">
              <input
                type="text"
                name="projectName"
                id="projectName"
                className="custom-input"
                placeholder="Project Name:"
                required
                onChange={handleChange}
              />
            </div>
            <div className="custom-input-container">
              <select
                defaultValue={"DEFAULT"}
                name="projectType"
                className="custom-input"
                id="projectType"
                required
                onChange={handleChange}
              >
                <option value="DEFAULT" disabled>
                  Project Type:
                </option>
                <option value="Website Development">Website Development</option>
                <option value="Platform Services">Platform Services</option>
                <option value="CRM Services">CRM Services</option>
                <option value="Webhook/API Services">
                  Webhook/API Services
                </option>
                <option value="Google Apps">Google Apps</option>
                <option value="Scripting">Scripting</option>
                <option value="Reporting Functions">Reporting Functions</option>
              </select>
            </div>
            <div className="custom-input-container">
              <textarea
                type="text"
                name="projectDescription"
                className="custom-input textarea"
                placeholder="Project Description:"
                required
                onChange={handleChange}
              />
            </div>
            <p className="responsive-text-36">Initial Deadline&nbsp;&nbsp;</p>
            <div className="custom-input-container">
              <input
                type="date"
                name="initialDeadline"
                id="suggestedETA"
                className="custom-input"
                onChange={handleChange}
                required
              />
            </div>
            <button
              type="submit"
              id="submitButton"
              className="custom-btn"
              disabled={isSubmitting}
            >
              {isSubmitting ? <Preloader /> : "Submit"}
            </button>
          </form>
        </div>
        <div
          style={{ display: isActiveCampaign ? "block" : "none" }}
          className={`${
            isActiveCampaign ? "d-flex justify-content-center" : ""
          }`}
        >
          <form
            className="form-container mx-3"
            id="activeCampaignForm"
            onSubmit={fetchData}
          >
            <header className="responsive-text-60 mb-3">Active Campaign</header>
            <div className="custom-input-container">
              <input
                type="text"
                name="email"
                className="custom-input"
                placeholder="Email:"
                required
                onChange={handleEmailChange}
              />
            </div>
            <button
              type="submit"
              id="submitButton"
              className="custom-btn"
              disabled={isSubmitting}
            >
              {isSubmitting ? <Preloader /> : "Submit"}
            </button>
          </form>

          <div id="tableContainer" style={{ display: "none" }}>
            <div className="table-responsive">
              <h2 className="responsive-text-60 my-5 text-center">Tickets</h2>
              <table id="table" className="table-dc">
                {isClient || email === "dev@dev" ? (
                  <thead className="table-dark">
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Project Name</th>
                      <th>Project Type</th>
                      <th>Project Description</th>
                      <th>Suggested ETA</th>
                      <th>Dev ETA</th>
                      <th>Progress</th>
                      <th>Submitted Time</th>
                      {email === "dev@dev" && <th>Dev Action</th>}
                    </tr>
                  </thead>
                ) : (
                  <h1 className="responsive-text-48">Not Found !!</h1>
                )}

                <tbody id="taskTableBody" className="table-body">
                  {tasks.map((task) =>
                    email === "dev@dev" || task.email === email ? (
                      <tr key={task.id}>
                        <td>{task.name}</td>
                        <td>{task.email}</td>
                        <td>{task.phone}</td>
                        <td>{task.projectName}</td>
                        <td>{task.projectType}</td>
                        <td>{task.projectDescription}</td>
                        <td>{task.initialDeadline}</td>
                        <td>{task.devETA}</td>
                        <td>
                          <div className="progress">
                            <div
                              className="progress-bar"
                              role="progressbar"
                              aria-valuemin="0"
                              aria-valuemax="100"
                              style={{ width: `${task.progress || 0}%` }}
                            >
                              {task.progress || 0}%
                            </div>
                          </div>
                        </td>
                        <td>{task.submittedTime}</td>
                        {email === "dev@dev" && (
                          <td>
                            <button
                              className="dev-btn"
                              data-task-id={task.id}
                              data-progress={task.progress || "0"}
                              data-dev-deadline={task.devETA}
                              onClick={() => onOpenModal(task)}
                            >
                              Dev Mode
                            </button>
                          </td>
                        )}
                      </tr>
                    ) : null
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* Modal  */}
      <div>
        <Modal open={open} onClose={onCloseModal} center>
          <h2>Update Task</h2>
          {currentTask && (
            <form onSubmit={handleModalSubmit}>
              <div className="custom-input-container">
                <label htmlFor="devDeadline">Dev Deadline:</label>
                <input
                  type="date"
                  name="devDeadline"
                  id="devDeadline"
                  defaultValue={currentTask.devETA}
                  required
                />
              </div>
              {/* <div className="custom-input-container">
                <label htmlFor="progressSlider">Progress:</label>
                <input
                  type="range"
                  name="progressSlider"
                  id="progressSlider"
                  min="0"
                  max="100"
                  defaultValue={currentTask.progress}
                  required
                  className="progress-bar"
                />
              </div> */}
              <div className="custom-input-container">
                <label htmlFor="progressSlider">Progress:</label>
                <input
                  type="range"
                  name="progressSlider"
                  id="progressSlider"
                  min="0"
                  max="100"
                  value={progress}
                  onChange={handleProgressChange}
                  required
                />
                <span>{progress}%</span>
              </div>
              <div className="d-flex justify-content-center mb-3">
                <button type="submit" className="hero-btn">
                  Update
                </button>
              </div>
            </form>
          )}
        </Modal>
      </div>
    </>
  );
}

export default App;
